<template>
  <form ref="form" @submit="handleValidate">
    <b-row>
      <b-col>
        <b-row style="padding-bottom: 47px">
          <b-col class="mb-3">
            <div
              style="
                width: 150px;
                height: 150px;
                background-color: #f3f6f9;
                box-shadow: 0px 4px 4px 0px #000000 10%;
                position: relative;
                margin: auto;
              "
            >
              <v-file-input
                hide-input
                v-model="file"
                accept="image/png, image/jpeg, image/bmp"
                class="icon-edit"
                @change="onUpload"
                v-if="!isDisabled"
                prepend-icon="mdi-pencil-outline"
                truncate-length="15"
              ></v-file-input>

              <v-img :src="preview" :aspect-ratio="1 / 1"></v-img>
              <!-- <v-icon
                @click="clear"
                v-if="preview && !isDisabled"
                style="position: absolute; bottom: -10px; right: -10px"
              >
                mdi-cancel</v-icon
              > -->
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <basic-input
              label="Mã món ăn"
              description
              placeholder="Mã món ăn"
              :value.sync="form.code"
              name="code"
              :disabled="isDisabled"
              :required="true"
              :state="validateState('code')"
              :invalidFeedback="errors.first('code')"
              v-validate="'required|alpha_num|max:100'"
              data-vv-as="Mã món ăn"
            ></basic-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <basic-input
              label="Tên món ăn"
              description
              placeholder="Tên món ăn"
              :value.sync="form.name"
              name="name"
              :disabled="isDisabled"
              :required="true"
              :state="validateState('name')"
              :invalidFeedback="errors.first('name')"
              v-validate="'required|max:100'"
              data-vv-as="Tên món ăn"
            ></basic-input>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="6">
            <label for="age"
              >Số lượng khẩu phần <span class="aterisk">*</span></label
            >
            <b-row>
              <b-col>
                <basic-input
                  :value.sync="form.portion"
                  name="portion"
                  input-type="number"
                  :required="true"
                  :disabled="isDisabled"
                  :state="validateState('portion')"
                  :invalidFeedback="errors.first('portion')"
                  v-validate="'required'"
                  data-vv-as="khẩu phần"
                ></basic-input>
              </b-col>
              <b-col>
                <basic-select
                  apiPath="/Food/Unit"
                  :options="rations"
                  :disabled="isDisabled"
                  :value.sync="form.foodUnitId"
                  :required="true"
                  name="rations"
                  label=""
                  :state="validateState('rations')"
                  :invalidFeedback="errors.first('rations')"
                  v-validate="'required'"
                  data-vv-as="đơn vị"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="6">
            <b-row>
              <b-col>
                <basic-input
                  label="Calo (cals)"
                  placeholder="(cals)"
                  :disabled="isDisabled"
                  :value.sync="form.calorie"
                  name="kcal"
                  :required="true"
                  inputType="number"
                  :state="validateState('kcal')"
                  :invalidFeedback="errors.first('kcal')"
                  v-validate="'required|decimal'"
                  data-vv-as="Calo (cals)"
                ></basic-input>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <basic-input
              label="Đạm (g)"
              placeholder="(g)"
              :disabled="isDisabled"
              :value.sync="form.protein"
              name="protein"
              :required="true"
              inputType="number"
              :state="validateState('protein')"
              :invalidFeedback="errors.first('protein')"
              v-validate="'required|decimal'"
              data-vv-as="Đạm (g)"
            ></basic-input>
          </b-col>

          <b-col>
            <basic-input
              label="Đường (g)"
              placeholder="(cals)"
              :disabled="isDisabled"
              :value.sync="form.glucose"
              name="glucose"
              :required="true"
              inputType="number"
              :state="validateState('glucose')"
              :invalidFeedback="errors.first('glucose')"
              v-validate="'required|decimal'"
              data-vv-as="Đường (g)"
            ></basic-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <basic-input
              label="Béo (g)"
              placeholder="(g)"
              :disabled="isDisabled"
              :value.sync="form.lipid"
              name="lipid"
              :required="true"
              inputType="number"
              :state="validateState('lipid')"
              :invalidFeedback="errors.first('lipid')"
              v-validate="'required|decimal'"
              data-vv-as="Béo (g)"
            ></basic-input>
          </b-col>
          <b-col>
            <basic-input
              label="Xơ (g)"
              placeholder="(g)"
              :disabled="isDisabled"
              :value.sync="form.fibre"
              name="fibre"
              :required="true"
              inputType="number"
              :state="validateState('fibre')"
              :invalidFeedback="errors.first('fibre')"
              v-validate="'required|decimal'"
              data-vv-as="Xơ (g)"
            ></basic-input>
          </b-col>
        </b-row>
      </b-col>

      <b-col>
        <b-row>
          <b-col> </b-col>
        </b-row>
        <b-row>
          <b-col>
            <basic-select
              label="Nhóm món ăn"
              name="groupCategory"
              placeholder="--- Chọn nhóm món ăn ---"
              :required="true"
              :options="groupFoods"
              :disabled="isDisabled"
              :value.sync="form.foodCategoryId"
              :state="validateState('groupCategory')"
              :invalidFeedback="errors.first('groupCategory')"
              v-validate="'required'"
              data-vv-as="Nhóm món ăn"
              groupLabel="name"
              groupValue="subCategories"
              :solid="false"
              :allowEmpty="false"
            />
            <!-- apiPath="/Admin/Diet/FoodCategory" -->
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <basic-text-area
              label="Mô tả"
              placeholder="Nhập ghi chú"
              :value.sync="form.description"
              name="description"
              :disabled="isDisabled"
              :state="validateState('description')"
              :invalidFeedback="errors.first('description')"
            ></basic-text-area>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <basic-text-area
              label="Cách chế biến"
              placeholder="Cách chế biến"
              :value.sync="form.recipe"
              name="recipe"
              :disabled="isDisabled"
              :state="validationState.recipe"
              :invalidFeedback="error.recipe"
            ></basic-text-area>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <basic-text-area
              label="Thành phần nguyên liệu"
              placeholder="Thành phần nguyên liệu"
              :value.sync="form.ingredient"
              name="ingredient"
              :disabled="isDisabled"
              :state="validationState.ingredient"
              :invalidFeedback="error.ingredient"
            ></basic-text-area>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <div class="d-flex justify-content-end">
      <b-button
        class="btn btn-plain ml-3"
        type="button"
        @click.stop="handleCancel"
      >
        Huỷ
      </b-button>
      <b-button
        class="btn btn-inactive ml-3"
        type="button"
        v-if="isEditing && form.isActive"
        @click.stop="handleChangeStatus"
      >
        <span class="svg-icon">
          <inline-svg src="/media/svg/icons/Neolex/Basic/power.svg" />
        </span>
        Inactive
      </b-button>
      <b-button
        v-if="isEditing"
        class="btn btn-success ml-3"
        type="button"
        :disabled="isDisabled"
        @click.stop="handleValidate"
      >
        <span class="svg-icon">
          <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
        </span>
        Cập nhật
      </b-button>
      <b-button
        class="btn btn-success ml-3"
        type="button"
        :disabled="isDisabled"
        v-if="!isEditing"
        @click.stop="handleValidate"
      >
        <span class="svg-icon">
          <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
        </span>
        Lưu
      </b-button>
    </div>
  </form>
</template>

<script>
export default {
  name: 'FormFood',
  props: {
    loading: { type: Boolean },
    type: { type: String },
    detail: { type: Object },
    id: { type: String },
    categories: { type: Array, default: () => [] },
  },
  data() {
    return {
      rations: [],
      groupFoods: [],
      file: null,
      preview: null,
      validationState: {},
      error: {},

      form: {
        // code: 'ma xin sof',
        // name: 'name xin sof',
        // portion: 1,
        // foodUnitId: null,
        // calorie: 999,
        // glucose: 111,
        // lipid: 222,
        // protein: 333,
        // fibre: 444,
        // foodCategoryId: null,
        // description: 'description',
        // recipe: 'secret',
        // ingredient: 'alots',
      },
    };
  },
  computed: {
    isDisabled() {
      return this.type === 'view';
    },
    isEditing() {
      return this.type === 'edit';
    },
  },
  watch: {
    categories: {
      immediate: true,
      deep: true,
      handler(newVal) {
        this.groupFoods = JSON.parse(JSON.stringify(newVal));
      },
    },
    detail: {
      deep: true,
      immediate: true,
      handler(newVal) {
        if (Object.keys(newVal).length > 0) {
          let clone = JSON.parse(JSON.stringify(newVal));
          this.form = clone;
          this.preview = clone.image?.url;
          this.form.foodCategoryId = {
            id: clone.foodCategoryId,
            name: clone.category,
          };
          this.form.foodUnitId = {
            id: clone.foodUnitId,
            name: clone.unit,
          };
        }
      },
    },
  },
  methods: {
    async handleChangeStatus() {
      this.$emit('update:loading', true);
      let payload = new FormData();
      payload.append('id', this.form.id);
      payload.append('isActive', !this.form.isActive);
      try {
        await this.$api.put('Admin/Food', payload);
        this.$toastr.s({
          title: 'Thành công!',
          msg: 'Cập nhật thành công',
        });
        this.$emit('update:loading', false);
        this.handleCancel();
      } catch (error) {
        this.$emit('update:loading', false);
        this.$toastr.e({
          title: 'Lỗi!',
          msg: error.error.message,
        });
      }
    },
    handleCancel() {
      this.$bvModal.hide('food-modal');
    },
    onUpload() {
      this.preview = URL.createObjectURL(this.file);
    },
    resetModal() {},
    handleValidate() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.submit();
        }
        return;
      });
    },
    async submit() {
      this.$emit('update:loading', true);
      let payload = new FormData();
      payload.append('code', this.form.code);
      payload.append('image', this.file);
      payload.append('name', this.form.name);
      payload.append('portion', this.form.portion);

      payload.append('calorie', this.form.calorie);
      payload.append('glucose', this.form.glucose);
      payload.append('lipid', this.form.lipid);
      payload.append('protein', this.form.protein);
      payload.append('fibre', this.form.fibre);

      this.form.description &&
        payload.append('description', this.form.description);
      this.form.recipe && payload.append('recipe', this.form.recipe);
      this.form.ingredient &&
        payload.append('ingredient', this.form.ingredient);
      try {
        if (typeof this.form.foodUnitId === 'object') {
          payload.append('foodUnitId', this.form.foodUnitId.id);
        } else {
          payload.append('foodUnitId', this.form.foodUnitId);
        }
        if (typeof this.form.foodCategoryId === 'object') {
          payload.append('foodCategoryId', this.form.foodCategoryId.id);
        } else {
          payload.append('foodCategoryId', this.form.foodCategoryId);
        }
        if (this.isEditing) {
          payload.append('id', this.form.id);

          await this.$api.put('Admin/Food', payload);
          this.$toastr.s({
            title: 'Thành công!',
            msg: 'Cập nhật thành công',
          });
        } else {
          await this.$api.post('Admin/Food', payload);
          this.$toastr.s({
            title: 'Thành công!',
            msg: 'Tạo mới thành công',
          });
        }
        this.$emit('update:loading', false);
        this.$emit('loadData');
        this.$bvModal.hide('food-modal');
      } catch (error) {
        this.$emit('update:loading', false);
        this.$toastr.e({
          title: 'Lỗi!',
          msg: error.message,
        });
      }
    },

    clear() {
      this.file = null;
      this.preview = null;
      this.form.image = null;
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },
  },
};
</script>

<style lang="scss">
.multiselect__option--group {
  font-weight: bold;
}
</style>
<style lang="scss" scoped>
.pictureInput {
  position: relative;
}
.icon-edit {
  z-index: 100000;
  position: absolute;
  top: -30px;
  right: -6px;
  padding: 2px 6px;
  box-shadow: -1px 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  width: fit-content;
  background-color: #fff;
}
</style>
