import { render, staticRenderFns } from "./Form.vue?vue&type=template&id=61623916&scoped=true&"
import script from "./Form.vue?vue&type=script&lang=js&"
export * from "./Form.vue?vue&type=script&lang=js&"
import style0 from "./Form.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./Form.vue?vue&type=style&index=1&id=61623916&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61623916",
  null
  
)

export default component.exports